import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ExternalLink = makeShortcode("ExternalLink");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`I implemented a QGIS plugin to integrate the `}<ExternalLink href="http://conefor.org/" mdxType="ExternalLink">{`Conefor`}</ExternalLink>{` landscape ecology software package.
The plugin features both a custom GUI dialog and also exposes all its core funcionality as QGIS Processing algorithms.`}</p>
    <p>{`This work was done under contract for a third party, and it is, unfortunately, not compatible with the latest QGIS version
anymore. Maybe I will be able to update it someday.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      